// Application variables
//
//


// Color
//
//

$white: #fff;
$black: #000;
$dark: #342E37;

$gray-100:      #f5f5f5;
$gray-200:      #eee;
$gray-300:      #ccc;
$gray-500:      #aaa;
$gray-600:      #888;
$gray-700:      #555;
$gray-800:      #333;
$gray-900:      #222;

$body-color:    $white;
$body-bg:       $dark;

$blue:          #048BA8;
$purple:        #6D466B;
$red:           #EC4E20;
$orange:        #F49D37;
$green:         #44AF69;

$primary:       $orange;
$success:       $green;
$info:          $blue;
$warning:       $orange;
$danger:        $red;



// Links
//
//

$link-color:                $primary;
$link-decoration:           none;
$link-hover-color:          darken($link-color, 15%);
$link-hover-decoration:     underline;


// Grid
//
//

$breakpoints-xs:   480px;
$breakpoints-sm:   767px;
$breakpoints-md:   991px;
$breakpoints-lg:   1200px;
$breakpoints-xl:   1400px;


// Components
//
//

$cubic-bezier:       cubic-bezier(0.25, 0.1, 0.25, 1);
$transition:         .3s $cubic-bezier;
$transition-base:    $transition;

$box-shadow:         0 10px 20px 0 rgba($black, .2);

$border-radius:      10px;

$font-weights: (
  100: 100,
  200: 200,
  300: 300,
  400: 400,
  500: 500,
  600: 600,
  700: 700,
  800: 800,
  900: 900
);


// Fonts
//
//

$font-family-sans-serif:      -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$font-family-monospace:       SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
$font-family-days:            'Days';
$font-family-idealist:        'IdealistSans';
$font-family-base:            $font-family-idealist, $font-family-sans-serif;