.a-logo {
  display: flex;
  align-items: center;
  font-size: 22px;
  font-family: $font-family-days;

  a {
    display: flex;
    align-items: center;
    width: 100%;
    text-decoration: none;
    color: $white;

    &:hover,
    &:focus {
      color: $primary;
    }
  }

  &-img {
    width: 60px;
    margin-right: 10px;
    color: $primary;

    svg,
    img {
      vertical-align: middle;
      max-width: 100%;
      height: auto;
    }
  }

  &-text {

    @media (max-width: $breakpoints-xs) {
      display: none;
    }
  }
}