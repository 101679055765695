.a-casino {
  margin-bottom: 40px;

  &-list {
    list-style: none;
    padding: 0;
    margin: 0;

    &__item {
      display: flex;
      align-items: center;
      width: 100%;
      border-bottom: 2px solid $primary;
      padding: 20px 0;

      @media (max-width: $breakpoints-md) {
        align-items: flex-start;
      }

      @media (max-width: $breakpoints-xs) {
        flex-wrap: wrap;
      }
    }

    &__img {
      width: 200px;
      border-radius: $border-radius;
      overflow: hidden;
      flex-shrink: 0;
      text-align: center;
      position: relative;
      box-shadow: $box-shadow;

      svg,
      img {
        max-width: 100%;
        height: auto;
      }

      &-logo {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba($dark, .8);

        svg,
        img {
          max-width: 100px;
          height: auto;
        }
      }
    }

    &__title {
      font-size: 20px;
      font-weight: 700;
      margin-left: 50px;

      @media (max-width: $breakpoints-md) {
        margin-left: 0;
        margin-top: 20px;
      }

      a {
        text-decoration: none;
        color: $white;

        &:hover,
        &:focus {
          color: $primary;
        }
      }
    }

    &__info {
      text-align: center;
      margin-right: 20px;
      width: 40%;
      display: flex;
      align-items: center;
      flex-shrink: 0;

      @media (max-width: $breakpoints-md) {
        flex-direction: column;
        width: 200px;
      }

      @media (max-width: $breakpoints-xs) {
        margin-bottom: 40px;
      }

      @media (max-width: $breakpoints-xs) {
        width: 100%;
      }
    }

    &__more {
      flex-grow: 1;
      display: flex;
      align-items: center;

      @media (max-width: $breakpoints-sm) {
        flex-direction: column;
        align-items: flex-end;
      }

      @media (max-width: $breakpoints-xs) {
        width: 100%;
        align-items: center;
      }
    }

    &__rating {
      flex-shrink: 0;
      margin-left: auto;
      display: flex;
      align-items: center;

      @media (max-width: $breakpoints-sm) {
        margin-bottom: 20px;
      }

      @media (max-width: $breakpoints-xs) {
        margin-left: inherit;
      }

      &-stars {
        display: flex;
        align-items: center;
        line-height: 0;

        img, svg {
          margin-right: 5px;
        }

        .is-active {
          color: $primary;
        }
      }

      &-count {
        margin-left: 10px;
        display: block;
      }
    }

    &__bonus {
      text-align: center;
      margin-right: 20px;
      font-size: 14px;
      font-weight: 300;

      @media (max-width: $breakpoints-md) {
        text-align: left;
      }

      @media (max-width: $breakpoints-sm) {
        text-align: right;
        margin-right: 0;
        margin-bottom: 20px;
      }

      @media (max-width: $breakpoints-xs) {
        text-align: center;
      }

      p {
        margin-top: 0;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &__btn {
      padding-left: 50px;
      flex-shrink: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      @media (max-width: $breakpoints-md) {
        padding-left: 20px;
      }

      @media (max-width: $breakpoints-xs) {
        padding-left: 0;
      }

      .a-btn {
        margin-bottom: 15px;
      }
    }

    &__link {
      position: relative;
      text-decoration: none;

      &:before {
        content: '';
        width: 100%;
        height: 2px;
        top: calc(100% - 2px);
        left: 0;
        position: absolute;
        background: currentColor;
      }

      &:hover,
      &:focus {
        color: $danger;
      }
    }
  }

  &-card {
    list-style: none;
    padding: 0;
    margin: 0 -15px;
    margin-bottom: -40px;
    display: flex;
    flex-wrap: wrap;
    text-align: center;

    li {
      width: 25%;
      padding: 0 15px;

      @media (max-width: $breakpoints-md) {
        width: 33.333%;
      }

      @media (max-width: $breakpoints-sm) {
        width: 50%;
      }

      @media (max-width: $breakpoints-xs) {
          width: 100%;
      }
    }

    &__item {
      border: 4px solid $primary;
      border-radius: $border-radius;
      overflow: hidden;
      margin: 0;
      margin-bottom: 40px;
      box-shadow: $box-shadow;

      figcaption {
        border-top: 4px solid $primary;
        padding: 15px;
        background-color: rgba($primary, .1);
      }

      &.is-simple {

        &:hover {

          .a-casino-card__img {

            &-logo {

              svg,
              img {
                transform: translateY(-40px);
              }
            }

            &-btn {
              transform: none;
              opacity: 1;
            }
          }
        }
      }
    }

    &__title {
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 20px;

      a {
        text-decoration: none;
        color: $white;

        &:hover,
        &:focus {
          color: $primary;
        }
      }
    }

    &__img {
      width: 100%;
      position: relative;

      img,
      svg {
        width: 100%;
        height: auto;
      }

      &-logo {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background-color: rgba($dark, .8);
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;

        svg, img {
          width: 100%;
          max-width: 150px;
          height: auto;
          transition: $transition;
        }
      }

      &-btn {
        transition: $transition;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        padding-bottom: 20px;
        transform: translateY(30px);
        opacity: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .a-btn {
          padding: 10px 20px;
          margin-bottom: 5px;
        }
      }
    }

    &__badge {
      width: 100px;
      height: 100px;
      position: absolute;
      left: 0;
      top: 0;
      transform: rotate(-45deg) translateY(-70%);
      display: flex;
      align-items: flex-end;
      padding-bottom: 10px;
      font-size: 14px;
      font-weight: 700;
      letter-spacing: 1px;
      justify-content: center;
      background: $danger;
      z-index: 1;
    }

    &__rating {
      flex-shrink: 0;
      margin-left: auto;
      display: flex;
      align-items: center;
      justify-content: center;

      &-stars {
        display: flex;
        align-items: center;
        line-height: 0;

        img, svg {
          margin-right: 5px;
        }

        .is-active {
          color: $primary;
        }
      }

      &-count {
        margin-right: 10px;
        font-weight: 700;
      }
    }

    &__bonus {
      font-size: 14px;
      font-weight: 300;
      margin-bottom: 20px;

      p {
        margin: 0;
      }
    }

    &__btn {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .a-btn {
        margin-bottom: 15px;
        width: 100%;
      }
    }

    &__link {
      position: relative;
      text-decoration: none;

      &:before {
        content: '';
        width: 100%;
        height: 2px;
        top: calc(100% - 2px);
        left: 0;
        position: absolute;
        background: currentColor;
      }

      &:hover,
      &:focus {
        color: $danger;
      }
    }
  }
}