.a-navigation {

  &-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  &-item {
    border: 1px solid $primary;
    border-left: 0;
    border-right: 0;

    &:not(:first-child) {
      margin-top: -1px;
    }
  }

  &-link {
    color: $white;
    display: block;
    padding: 10px 0;
    text-decoration: none;

    &:hover,
    &:focus {
      color: $primary;
    }
  }
}