.a-footer {
  margin-top: 80px;
  border-top: 2px solid $primary;
  padding: 30px 0;
  background: darken($dark, 8%);
  box-shadow: inset 0 5px 10px rgba($black, .5);
  text-align: center;
  font-size: 14px;
  font-weight: 300;

  &-social {
    flex-grow: 1;
    text-align: center;
    margin-bottom: 40px;

    ul {
      display: inline-flex;
      flex-wrap: wrap;
      align-items: center;
      list-style: none;
      padding: 0;
      margin: 0 -5px;
    }

    li {
      padding: 0 5px;
    }

    a {
      color: $white;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      border: 2px solid rgba($primary, .5);
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover,
      &:focus {
        border-color: $primary;
        color: $primary;
      }
    }

    svg,
    img {
      max-width: 20px;
      max-height: 20px;
      width: 100%;
      height: auto;
      vertical-align: middle;
    }
  }

  &-nav {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
    text-align: left;
    margin-bottom: 40px;

    &__item {
      width: 25%;
      padding: 0 15px;
      margin-bottom: 30px;

      @media (max-width: $breakpoints-md) {
        width: 33.333%;
      }

      @media (max-width: $breakpoints-sm) {
        width: 50%;
      }

      @media (max-width: $breakpoints-xs) {
        width: 100%;
      }

      .full & {
        width: 100%;
        column-count: 4;
        column-gap: 30px;

        @media (max-width: $breakpoints-md) {
          column-count: 3;
        }

        @media (max-width: $breakpoints-sm) {
          column-count: 2;
        }

        @media (max-width: $breakpoints-xs) {
          column-count: 1;
        }
      }
    }

    &__list {
      list-style: none;
      padding: 0;
      margin: 0;

      a {
        padding: 3px 0;
        display: inline-block;
        color: $white;

        &:hover,
        &:focus {
          color: $primary;
        }
      }
    }

    &__title {
      display: block;
      margin-bottom: 30px;
      font-weight: 700;
      position: relative;
      font-size: 16px;

      &:before {
        content: '';
        width: 20px;
        height: 2px;
        background-color: $primary;
        position: absolute;
        left: 0;
        top: calc(100% + 5px);
      }
    }
  }
}
