.a-btn {
  display: inline-block;
  background: none;
  border: none;
  border-radius: $border-radius;
  padding: 15px 30px;
  text-decoration: none;
  transition: $transition;
  box-shadow: $box-shadow;
  text-align: center;

  &-primary {
    background-color: $primary;
    color: $white;

    &:hover {
      background-color: darken($primary, 10%);
    }

    &:focus {
      box-shadow: 0 0 0 3px rgba($primary, .5);
    }
  }

  &-danger {
    background-color: $danger;
    color: $white;

    &:hover {
      background-color: darken($danger, 10%);
    }

    &:focus {
      box-shadow: 0 0 0 3px rgba($danger, .5);
    }
  }

  &-info {
    background-color: $info;
    color: $white;

    &:hover {
      background-color: darken($info, 10%);
    }

    &:focus {
      box-shadow: 0 0 0 3px rgba($info, .5);
    }
  }

  &-success {
    background-color: $success;
    color: $white;

    &:hover {
      background-color: darken($success, 10%);
    }

    &:focus {
      box-shadow: 0 0 0 3px rgba($success, .5);
    }
  }

  &-block {
    display: block;
  }

  &-wrap {
    margin: 40px 0;
  }
}