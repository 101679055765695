* {
    box-sizing: border-box;
}

body {
    padding-top: 100px;
}

.a-box {
    padding: 0 15px;
    max-width: 1400px;
    margin: 0 auto;
}

.a-content {
    margin: 0 -15px;
    display: flex;

    @media (max-width: $breakpoints-sm) {
        flex-wrap: wrap;
    }

    &__main {
        flex-grow: 1;
        padding: 0 15px;
        margin-bottom: 40px;

        @media (max-width: $breakpoints-sm) {
            width: 100%;
        }

        .a-slots-list li {
            width: 33.333%;

            @media (max-width: $breakpoints-lg) {
                width: 50%;
            }

            @media (max-width: 900px) {
                width: 100%;
            }

            @media (max-width: $breakpoints-sm) {
                width: 50%;
            }

            @media (max-width: $breakpoints-xs) {
                width: 100%;
            }
        }

        .a-casino-card li {
            width: 33.333%;

            @media (max-width: $breakpoints-lg) {
                width: 50%;
            }

            @media (max-width: 900px) {
                width: 100%;
            }

            @media (max-width: $breakpoints-sm) {
                width: 50%;
            }

            @media (max-width: $breakpoints-xs) {
                width: 100%;
            }
        }
    }

    &__sidebar {
        width: 350px;
        padding: 0 15px;
        margin-bottom: 40px;
        flex-shrink: 0;

        @media (max-width: $breakpoints-sm) {
            width: 100%;
        }

        .a-slots-list li {
            width: 100%;

            @media (max-width: $breakpoints-sm) {
                width: 50%;
            }

            @media (max-width: $breakpoints-xs) {
                width: 100%;
            }
        }

        .a-casino-card li {
            width: 100%;

            @media (max-width: $breakpoints-sm) {
                width: 50%;
            }

            @media (max-width: $breakpoints-xs) {
                width: 100%;
            }
        }
    }

    &__sticky {
        position: sticky;
        top: 100px;
    }
}
