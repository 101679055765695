.a-header {
  position: fixed;
  background-color: rgba($dark, .97);
  top: 0;
  left: 0;
  right: 0;
  z-index: 111;
  border-bottom: 2px solid $primary;
  box-shadow: 0 10px 20px 0 $dark;

  &-wrap {
    display: flex;
    align-items: center;
    padding: 5px 0;
  }

  &-social {
    flex-grow: 1;
    text-align: center;

    @media (max-width: $breakpoints-sm) {
      display: none;
    }

    ul {
      display: inline-flex;
      flex-wrap: wrap;
      align-items: center;
      list-style: none;
      padding: 0;
      margin: 0 -5px;
    }

    li {
      padding: 0 5px;
    }

    a {
      color: $white;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      border: 2px solid rgba($primary, .5);
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover,
      &:focus {
        border-color: $primary;
        color: $primary;
      }
    }

    svg,
    img {
      max-width: 20px;
      max-height: 20px;
      width: 100%;
      height: auto;
      vertical-align: middle;
    }
  }

  &-nav {

    @media (max-width: $breakpoints-sm) {
      position: absolute;
      opacity: 0;
      visibility: hidden;
      top: 100%;
      left: 0;
      right: 0;
      background-color: rgba($dark, .97);
      transition: $transition;
      padding: 15px;
      z-index: -1;
      transform: translateY(-20px);

      .is-hamburger-open & {
        transform: none;
        visibility: visible;
        opacity: 1;
        border-bottom: 2px solid $primary;
      }
    }

    ul {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      list-style: none;
      padding: 0;
      margin: 0 -15px;

      @media (max-width: $breakpoints-sm) {
        justify-content: center;
      }
    }

    a {
      color: $white;
      padding: 15px;
      text-decoration: none;

      &:hover,
      &:focus {
        color: $primary;
      }
    }
  }

  &-hamburger {
    display: none;
    margin-left: auto;

    .icon {
      display: block;
      width: 40px;
      height: 20px;
      position: relative;

      .is-hamburger-open & {

        div {

          &:nth-child(1) {
            top: calc(50% - 1px);
            transform: rotate(-45deg);
          }

          &:nth-child(2) {
            width: 0;
          }

          &:nth-child(3) {
            bottom: calc(50% - 1px);
            transform: rotate(45deg);
          }
        }
      }

      div {
        width: 100%;
        position: absolute;
        height: 2px;
        border-radius: 2px;
        background: currentColor;
        transition: $transition;

        &:nth-child(1) {
          top: 0;
          left: 0;
        }

        &:nth-child(2) {
          top: calc(50% - 1px);
          right: 0;
        }

        &:nth-child(3) {
          bottom: 0;
          left: 0;
        }
      }
    }

    @media (max-width: $breakpoints-sm) {
      display: block;
    }
  }
}
