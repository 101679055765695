html {
    font-size: 18px;
    line-height: 1.5;
    color: $body-color;
    font-family: $font-family-base;

    @media (max-width: $breakpoints-md) {
        font-size: 16px;
    }
}

body {
    background: $body-bg;
    fill: $body-color;
}

img,
svg {
    vertical-align: middle;
}

* {
    outline: none !important;
}

hr {
    margin: 50px 0;
    border-color: rgba($white, .2);
}

button,
.btn,
a {
    color: $link-color;
    transition: $transition;
}

h1, .h1, h2, .h2, h3, .h3,
h4, .h4, h5, .h5, h6, .h6 {
    display: block;
    margin-top: 40px;
    margin-bottom: 20px;
    font-weight: 700;
}

.h1, h1 {
    font-size: 36px;
}

.h2, h2 {
    font-size: 32px;
}

.h3, h3 {
    font-size: 28px;
}

.h4, h4 {
    font-size: 24px;
}

.h5, h5 {
    font-size: 20px;
}

@each $class, $value in $font-weights {
    @include font-weight(".font-weight-#{$class}", $value);
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.text-left {
    text-align: left;
}

.line {
    margin-bottom: 80px;
    position: relative;
    margin-top: 0;

    &:before {
        content: '';
        height: 4px;
        width: 80px;
        background: $primary;
        position: absolute;
        top: calc(100% + 20px);
        left: 0;
    }

    &:after {
        content: '';
        height: 2px;
        width: 40px;
        background: $primary;
        position: absolute;
        top: calc(100% + 40px);
        opacity: .5;
        left: 0;
    }

    .text-center &,
    &.text-center {

        &:before,
        &:after {
            left: 50%;
            transform: translateX(-50%);
        }
    }
}

.rounded {
    border-radius: $border-radius;
}

.shadow {
    box-shadow: $box-shadow;
}

iframe,
embed {
    border: 4px solid $primary;
    border-radius: $border-radius;
    overflow: hidden;
}

textarea {
    resize: vertical;
}

abbr[title] {
    text-decoration: none;
    border-bottom: 0;
    cursor: inherit !important;
}