@mixin font-face($family, $filepath, $weight: normal, $style: normal) {
	@font-face {
		font-family: $family;
		src: url('#{$filepath}.woff') format('woff'),
			 url('#{$filepath}.woff2') format('woff2');
		font-weight: $weight;
		font-style: $style;
	}
}

@include font-face('Days', '../fonts/Days/Days', 400);

@include font-face('IdealistSans', '../fonts/IdealistSans/IdealistSans-Light', 300);
@include font-face('IdealistSans', '../fonts/IdealistSans/IdealistSans-Regular', 400);