.a-payment {
  margin: 80px 0;

  &-list {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
    padding: 0;
    align-items: center;
    justify-content: center;
  }

  &-item {
    padding: 0 20px;
    width: 16.666%;

    @media (max-width: $breakpoints-md) {
      width: 25%;
    }

    @media (max-width: $breakpoints-xs) {
      width: 33.333%;
    }

    img {
      max-width: 100%;
      height: auto;
    }
  }
}