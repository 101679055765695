.a-text {
  margin-bottom: 40px;

  .img-left {
    float: left;
    margin-bottom: 20px;
    margin-right: 20px;

    @media (max-width: $breakpoints-md) {
      width: 100%;
      margin-right: 0;
    }
  }

  .img-right {
    float: right;
    margin-bottom: 20px;
    
    @media (max-width: $breakpoints-md) {
      width: 100%;
      margin-right: 0;
    }
  }

  &-rating {
    flex-shrink: 0;
    margin-left: auto;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  
    &__stars {
      display: flex;
      align-items: center;
      line-height: 0;
  
      img, svg {
        margin-right: 5px;
      }
  
      .is-active {
        color: $primary;
      }
    }
  
    &__count {
      margin-right: 10px;
      font-weight: 700;
    }
  }
}