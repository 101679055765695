.a-slots {
  margin-bottom: 40px;

  &-list {
    list-style: none;
    padding: 0;
    margin: 0 -15px;
    margin-bottom: -40px;
    display: flex;
    flex-wrap: wrap;

    li {
      width: 33.333%;
      padding: 0 15px;
      margin-bottom: 40px;

      @media (max-width: $breakpoints-md) {
        width: 50%;
      }

      @media (max-width: $breakpoints-xs) {
          width: 100%;
      }
    }
  }

  &-item {
    margin: 0;
    width: 100%;
    position: relative;
    border-radius: $border-radius;
    overflow: hidden;
    border: 4px solid $primary;
    background: $primary;
    box-shadow: $box-shadow;

    &:hover {

      .a-slots-item__overlay {
        opacity: 1;
        visibility: visible;
      }
    }

    img {
      width: 100%;
      height: auto;
      border-radius: $border-radius;
    }

    figcaption {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1;
      text-align: center;
      padding: 10px;
      background-color: $primary;
      color: $white;
    }

    &__overlay {
      padding: 15px;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba($dark, .7);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      opacity: 0;
      visibility: hidden;
      transition: $transition;
      z-index: 1;

      .a-btn {
        width: 155px;
        max-width: 100%;

        + .a-btn {
          margin-top: 10px;
        }
      }
    }

    &__link {
      display: block;
    }
  }
}